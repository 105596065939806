import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { FaGithub } from 'react-icons/fa'

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#d0d0d0`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <a
        href="https://github.com/jimmyzmli"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Github link"
      >
        <FaGithub style={{color: 'black'}} />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
