import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { usePageContext } from '../context'

const PageLink = styled(Link)`
  background: ${props => props.theme.isCurrentPath ? 'yellow' : 'none'};
  display: block;
`;

const Nav = () => {
  const { location } = usePageContext()
  const getPathInfo = (toPathname) => ({
    to: toPathname,
    theme: {
      isCurrentPath: toPathname === location.pathname
    }
  })

  return (
    <nav>
      <PageLink {...getPathInfo("/")} >Home</PageLink>
      <PageLink {...getPathInfo("/projects")} >Projects</PageLink>
      <PageLink {...getPathInfo("/contact")} >Contact Me</PageLink>
    </nav>
  )
};
export default Nav